// Example: OrdersPage.tsx
import React, { useState } from 'react';
import OrderFilter from './OrderFilter';
import { Order, OrderFilters } from '../../interfaces/test';

const OrdersPage: React.FC = () => {
    const [orders, setOrders] = useState<Order[]>();
    const [filters, setFilters] = useState<OrderFilters>({});

    const fetchFilteredOrders = async (filters: OrderFilters) => {
        const query = new URLSearchParams(filters as any).toString();
        const response = await fetch(`http://localhost:5000/api/v1/orders/filter?${query}`);
        if (!response.ok) {
            // Handle error
            console.error('Failed to fetch orders');
            return;
        }
        let res = await response.json();
        const data = res.data;
        setOrders(data);
    };

    const downloadReport = async (filters: OrderFilters) => {
        const query = new URLSearchParams(filters as any).toString();
        const response = await fetch(`http://localhost:5000/api/v1/orders/download?${query}`, {
            method: 'GET',
        });
        if (!response.ok) {
            // Handle error
            console.error('Failed to download report');
            return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders_report.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <div>
            <OrderFilter onFilter={fetchFilteredOrders} />
            <button onClick={() => downloadReport(filters)}>Download Report</button>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>OrderPrice</th>
                        <th>PaymentMethod</th>
                        <th>IsReplacementOrder</th>
                        <th>QTY</th>
                        <th>Product</th>
                        <th>ASIN</th>
                        <th>SKU</th>
                        <th>TotalExpense</th>
                        <th>EstimatedPayout</th>
                    </tr>
                </thead>
                <tbody>
                    {orders && orders.map((order) => (
                        <tr key={order.OrderId}>
                            <td>{order.OrderId}</td>
                            <td>{order.Status}</td>
                            <td>{order.OrderDate}</td>
                            <td>{order.OrderPrice}</td>
                            <td>{order.PaymentMethod}</td>
                            <td>{order.IsReplacementOrder}</td>
                            <td>{order.QTY}</td>
                            <td>{order.Product}</td>
                            <td>{order.ASIN}</td>
                            <td>{order.SKU}</td>
                            <td>{order.TotalExpense}</td>
                            <td>{order.EstimatedPayout}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrdersPage;
