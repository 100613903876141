'use client';

import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import { navItems } from './config';
import { navIcons } from './NavIcons';
import { Logo } from '../../Core/Logo';
import { isNavItemActive } from '../../../utils/IsNavItemActive';
import { paths } from '../../../paths';
import { Helper } from '../../../utils/Helper';
import { NavItemConfig } from '../../../interfaces/NavItemConfig';
import { UserPayload } from '../../../interfaces/UserPayload';

export interface MobileNavProps {
    onClose?: () => void;
    open?: boolean;
    items?: NavItemConfig[];
}

const MobileNav: React.FC<MobileNavProps> = ({ open, onClose }) => {
    const location = useLocation();
    
    const [User, setUser] = React.useState<UserPayload>({ email: '', role: 1, organization: ''});
    React.useEffect(() => {
      const User = Helper.getCurrentUser();
      setUser(User);
    },[]);

    return (
        <Drawer
            PaperProps={{
                sx: {
                    '--MobileNav-background': 'var(--mui-palette-neutral-950)',
                    '--MobileNav-color': 'var(--mui-palette-common-white)',
                    '--NavItem-color': 'var(--mui-palette-neutral-300)',
                    '--NavItem-hover-background': 'rgba(255, 255, 255, 0.04)',
                    '--NavItem-active-background': 'var(--mui-palette-primary-main)',
                    '--NavItem-active-color': 'var(--mui-palette-primary-contrastText)',
                    '--NavItem-disabled-color': 'var(--mui-palette-neutral-500)',
                    '--NavItem-icon-color': 'var(--mui-palette-neutral-400)',
                    '--NavItem-icon-active-color': 'var(--mui-palette-primary-contrastText)',
                    '--NavItem-icon-disabled-color': 'var(--mui-palette-neutral-600)',
                    bgcolor: 'var(--MobileNav-background)',
                    color: 'var(--MobileNav-color)',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                    scrollbarWidth: 'none',
                    width: 'var(--MobileNav-width)',
                    zIndex: 'var(--MobileNav-zIndex)',
                    '&::-webkit-scrollbar': { display: 'none' },
                },
            }}
            onClose={onClose}
            open={open}
        >
            <Stack spacing={2} sx={{ p: 3 }}>
                <Box component={RouterLink} to={paths.home} sx={{ display: 'inline-flex' }}>
                    <Logo color="light" height={32} width={122} />
                </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        backgroundColor: 'var(--mui-palette-neutral-950)',
                        border: '1px solid var(--mui-palette-neutral-700)',
                        borderRadius: '12px',
                        cursor: 'pointer',
                        display: 'flex',
                        p: '4px 12px',
                    }}
                >
                    <Box sx={{ flex: '1 1 auto' }}>
                        <Typography color="var(--mui-palette-neutral-400)" variant="body2">
                            Organization
                        </Typography>
                        <Typography color="inherit" variant="subtitle1">
                            {User.organization}
                        </Typography>
                    </Box>
                    <UnfoldMoreIcon />
                </Box>
            </Stack>
            <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
            <Box component="nav" sx={{ flex: '1 1 auto', p: '12px' }}>
                {renderNavItems({ pathname: location.pathname, items: navItems })}
            </Box>
            <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
            <Stack spacing={2} sx={{ p: '12px' }}>
                
            </Stack>
        </Drawer>
    );
}

function renderNavItems({ items = [], pathname }: { items?: NavItemConfig[]; pathname: string }): React.JSX.Element {
    const children = items.reduce((acc: React.ReactNode[], curr: NavItemConfig): React.ReactNode[] => {
        const { key, ...item } = curr;

        acc.push(<NavItem key={key} pathname={pathname} {...item} />);

        return acc;
    }, []);

    return (
        <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
            {children}
        </Stack>
    );
}

interface NavItemProps extends Omit<NavItemConfig, 'items'> {
    pathname: string;
}

function NavItem({ disabled, external, href, icon, matcher, pathname, title }: NavItemProps): React.JSX.Element {
    const active = isNavItemActive({ disabled, external, href, matcher, pathname });
    const Icon = icon ? navIcons[icon] : null;

    return (
        <li>
            <Box
                component={external ? 'a' : RouterLink}
                to={href || '#'}
                target={external ? '_blank' : undefined}
                rel={external ? 'noreferrer' : undefined}
                sx={{
                alignItems: 'center',
                borderRadius: 1,
                color: 'var(--NavItem-color)',
                cursor: 'pointer',
                display: 'flex',
                flex: '0 0 auto',
                gap: 1,
                p: '6px 16px',
                position: 'relative',
                textDecoration: 'none',
                whiteSpace: 'nowrap',
                ...(disabled && {
                    bgcolor: 'var(--NavItem-disabled-background)',
                    color: 'var(--NavItem-disabled-color)',
                    cursor: 'not-allowed',
                }),
                ...(active && { bgcolor: 'var(--NavItem-active-background)', color: 'var(--NavItem-active-color)' }),
                }}
            >
                <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flex: '0 0 auto' }}>
                    {Icon ? (
                        <Icon
                            fill={active ? 'var(--NavItem-icon-active-color)' : 'var(--NavItem-icon-color)'}
                        //   fontSize="var(--icon-fontSize-md)"
                        //   weight={active ? 'fill' : undefined}
                        />
                    ) : null}
                </Box>
                <Box sx={{ flex: '1 1 auto' }}>
                    <Typography
                        component="span"
                        sx={{ color: 'inherit', fontSize: '0.875rem', fontWeight: 500, lineHeight: '28px' }}
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
        </li>
    );
}

export default MobileNav;