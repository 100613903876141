import * as React from 'react';
import ResetPasswordForm from '../../../components/Auth/ResetPasswordForm';

const ResetPassword: React.FC = () => {
  return (
    <ResetPasswordForm/>
  );
}

export default ResetPassword;
