import type { PaletteRange } from '@mui/material/styles/createPalette';

export const california = {
  50: '#fffaea',
  100: '#fff3c6',
  200: '#ffe587',
  300: '#ffd049',
  400: '#ffbb1f',
  500: '#fb9c0c',
  600: '#de7101',
  700: '#b84d05',
  800: '#953b0b',
  900: '#7b310c',
  950: '#471701',
} satisfies PaletteRange;

export const kepple = {
  50: '#f0fdfa',
  100: '#ccfbef',
  200: '#9af5e1',
  300: '#5fe9ce',
  400: '#2ed3b8',
  500: '#15b79f',
  600: '#0e9382',
  700: '#107569',
  800: '#115e56',
  900: '#134e48',
  950: '#042f2c',
} satisfies PaletteRange;

// export const neonBlue = {
//   50: '#ecf0ff',
//   100: '#dde3ff',
//   200: '#c2cbff',
//   300: '#9ca7ff',
//   400: '#7578ff',
//   500: '#635bff',
//   600: '#4e36f5',
//   700: '#432ad8',
//   800: '#3725ae',
//   900: '#302689',
//   950: '#1e1650',
// } satisfies PaletteRange;

export const vibrantOrange = {
  50: '#FFEFE5',
  100: '#FFD8BF',
  200: '#FFBA99',
  300: '#FF9D73',
  400: '#FF7F4D',
  500: '#F26723',
  600: '#D8551F',
  700: '#BF441B',
  800: '#A63317',
  900: '#8C2213',
  950: '#66110A',
} satisfies PaletteRange;

export const deepBlue = {
  50: '#E5ECF4',
  100: '#BFCEE1',
  200: '#95ABCB',
  300: '#6B88B5',
  400: '#4B6DA4',
  500: '#173969',
  600: '#153361',
  700: '#122C56',
  800: '#0F244B',
  900: '#0B193A',
  950: '#050C1E',
} satisfies PaletteRange;

// export const nevada = {
//   50: '#fbfcfe',
//   100: '#f0f4f8',
//   200: '#dde7ee',
//   300: '#cdd7e1',
//   400: '#9fa6ad',
//   500: '#636b74',
//   600: '#555e68',
//   700: '#32383e',
//   800: '#202427',
//   900: '#121517',
//   950: '#090a0b',
// } satisfies PaletteRange;

export const redOrange = {
  50: '#fef3f2',
  100: '#fee4e2',
  200: '#ffcdc9',
  300: '#fdaaa4',
  400: '#f97970',
  500: '#f04438',
  600: '#de3024',
  700: '#bb241a',
  800: '#9a221a',
  900: '#80231c',
  950: '#460d09',
} satisfies PaletteRange;

export const shakespeare = {
  50: '#ecfdff',
  100: '#cff7fe',
  200: '#a4eefd',
  300: '#66e0fa',
  400: '#10bee8',
  500: '#04aad6',
  600: '#0787b3',
  700: '#0d6d91',
  800: '#145876',
  900: '#154964',
  950: '#082f44',
} satisfies PaletteRange;

export const stormGrey = {
  50: '#f9fafb',
  100: '#f1f1f4',
  200: '#dcdfe4',
  300: '#b3b9c6',
  400: '#8a94a6',
  500: '#667085',
  600: '#565e73',
  700: '#434a60',
  800: '#313749',
  900: '#212636',
  950: '#121621',
} satisfies PaletteRange;
