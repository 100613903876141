import { Button, Dialog, DialogActions, DialogContent, List, ListItem, ListItemText, TextField } from '@mui/material';
import React, { ChangeEvent, useState } from 'react'

const items = [
    'Amazon',
    'Flipkart',
    'Jio',
    'Myntra',
    'Meesho',
    'Snapdeal',
];


export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

const SimpleDialog: React.FC<SimpleDialogProps> = (props) => {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filteredItems, setFilteredItems] = useState<string[]>(items);

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query) {
            setFilteredItems(items.filter(item =>
                item.toLowerCase().includes(query.toLowerCase())
            ));
        } else {
            setFilteredItems(items);
        }
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth >
            <DialogContent>
                <TextField
                    label="Search"
                    name="search"
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                />
                {searchQuery && filteredItems.map((item, index) => (
                    <List>
                        <ListItem key={index}>
                            <ListItemText primary={item} />
                        </ListItem>
                    </List>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default SimpleDialog