import { JwtPayload, jwtDecode } from "jwt-decode";

interface userDataPayload extends JwtPayload {
    email: string,
    organization: string,
    role: string,
    iat: number,
    exp: number
}

export class Helper {

    public static decodeToken(token: string) {
        try {
            const userData = jwtDecode<userDataPayload>(token);
            return userData;
        } catch (err) {
            console.log('Token verification failed: ', err);
            return null;
        }
    }

    public static setStorageData(key: string, data: any): void {
        window.localStorage.setItem(key, data);
    }

    public static getStorageData(key: string) {
        return window.localStorage.getItem(key);
    }

    public static removeStorageData(key: string): void {
        window.localStorage.removeItem(key);
    }

    public static getCurrentUser() {
        const token = this.getStorageData('userToken');
        const user = this.getStorageData('user');
        if (token && user) {
            return JSON.parse(user);
        }
        else return null;
    }
}
