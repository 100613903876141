import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

export interface Integration {
    id: string;
    title: string;
    description: string;
    logo: string;
    link: string;
}

export interface IntegrationCardProps {
    integration: Integration;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({ integration }) => {
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent sx={{ flex: '1 1 auto' }}>
                <Stack spacing={2}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Avatar src={integration.logo} variant="square" />
                    </Box>
                    <Stack spacing={1}>
                        <Typography align="center" variant="h5">
                            {integration.title}
                        </Typography>
                        <Typography align="center" variant="body1">
                            {integration.description}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
            <Divider />
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center', p: 1 }}>
                <Box
                    component={Link}
                    to={integration.link}
                    sx={{ width: '100%'}}
                >
                    <Box
                        component={Button}
                        variant='contained'
                        fullWidth
                    >
                        Connect with {integration.title}
                    </Box>
                </Box>
            </Stack>
        </Card>
    );
}

export default IntegrationCard;