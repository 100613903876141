import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import { PieChart } from '@mui/x-charts/PieChart';
import { Card, CardContent, CardHeader, SxProps } from '@mui/material';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { PieSeriesType, PieValueType } from '@mui/x-charts/models';

interface revenueExpenseData {
    type: string;
    value: number;
}

interface PieChartProp {
    data: { label: string, value: number }[];
    sx?: SxProps;
}

const PieClickNoSnap: React.FC<PieChartProp> = ({ data, sx }) => {
    const [itemData, setItemData] = React.useState<revenueExpenseData | null>();
    const series: MakeOptional<PieSeriesType<MakeOptional<PieValueType, "id">>, "type">[] = [
        {
            innerRadius: 0,
            outerRadius: 90,
            id: 'revenueExpense',
            data: data,
            highlightScope: { fade: 'global', highlight: 'item' },
            // faded: { innerRadius: 50, additionalRadius: -50, color: 'gray' },
        },
    ];
    return (
        <Card sx={sx}>
            <CardHeader title='Pie Chart' />
            <CardContent>

                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={{ xs: 0, md: 4 }}
                    sx={{ width: '100%' }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <PieChart
                            series={series}
                            width={400}
                            height={300}
                            slotProps={{
                                legend: { hidden: true },
                            }}
                            onItemClick={(event, d) => {
                                let chartData = {
                                    type: data[d.dataIndex].label,
                                    value: data[d.dataIndex].value
                                }
                                setItemData(chartData)
                            }}
                        />{' '}
                    </Box>

                    <Stack direction="column" sx={{ width: { xs: '100%', md: '40%' } }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography>Click on the chart</Typography>
                            <IconButton
                                aria-label="reset"
                                size="small"
                                onClick={() => {
                                    setItemData(null);
                                }}
                            >
                                <UndoOutlinedIcon fontSize="small" />
                            </IconButton>
                        </Box>
                        <Box>
                            <p>// Data from item click</p>
                            {itemData ? JSON.stringify(itemData, null, 2) : <p>// The data will appear here</p>}
                        </Box>
                        {/* <HighlightedCode
                            code={`// Data from item click
${itemData ? JSON.stringify(itemData, null, 2) : '// The data will appear here'}
`}
                            language="json"
                            copyButtonHidden
                        /> */}
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}



export default PieClickNoSnap;