import React from 'react'
import MainLayout from '../Layout'
import { Box, Container, Grid, Typography } from '@mui/material'
import PageContainer from '../../../components/PageContainer/PageContainer'

const PrivacyPolicy: React.FC = () => {
    return (
        <React.Fragment>
            <MainLayout>
                <PageContainer title='Privacy Policy | Virtacc' description='This is Privacy Policy page of Virtacc'>
                    <Box id="head" sx={{ backgroundColor: 'background.paper', position: 'relative', pt: 4, pb: { xs: 8, md: 10 } }}>
                        <Container maxWidth="lg">
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        // textAlign: { xs: 'center', md: 'left' },
                                        textAlign: 'left',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box sx={{ mb: 3 }}>
                                        <Typography
                                            component="h6"
                                            sx={{
                                                position: 'relative',
                                                fontSize: { xs: 30, md: 45 },
                                                letterSpacing: 1.5,
                                                fontWeight: 'bold',
                                                lineHeight: 1.3,
                                            }}
                                        >
                                            Privacy Policy
                                        </Typography>
                                    </Box>

                                    {/* OVERVIEW */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Overview
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }} >
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>The security of your data is very important for us. We always treat your personal and business data confidentially and with great care. Your business data and KPI’s (e.g. sales statistics) are used exclusively for the operation of the Virtacc software. Under no circumstances will your data be analyzed, evaluated, interpreted, sold or used outside Virtacc. You can be sure that it’s our top priority to ensure that your business secrets are safe!</p>
                                            <p>The use of the internet pages of Virtacc (herafter referred to as Virtacc) is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary. If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.</p>
                                            <p>The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data protection regulations applicable to Virtacc. By means of this data protection declaration, our enterprise would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the rights to which they are entitled.</p>
                                            <p>As the controller, Virtacc has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. However, Internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason, every data subject is free to transfer personal data to us via alternative means, e.g. by telephone.</p>
                                        </Typography>
                                    </Box>

                                    {/* DEFINITIONS */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Definitions
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Our data protection declaration should be legible and understandable for the general public, as well as our customers and business partners. To ensure this, we would like to first explain the terminology used.</p>
                                            <p>In this data protection declaration, we use the following terms:</p>
                                        </Typography>
                                    </Box>

                                    {/* A) PERSONAL DATA */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            a) Personal Data
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Personal data means any information relating to an identified or identifiable natural person (“data subject”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
                                        </Typography>
                                    </Box>

                                    {/* B) DATA SUBJECT */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            b) Data Subject
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Data subject is any identified or identifiable natural person, whose personal data is processed by the controller responsible for the processing.</p>
                                        </Typography>
                                    </Box>

                                    {/* C) PROCESSING */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            c) Processing
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Processing is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</p>
                                        </Typography>
                                    </Box>

                                    {/* D) RESTRICTION OF PROCESSING */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            d) Restriction of Processing
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Restriction of processing is the marking of stored personal data with the aim of limiting their processing in the future.</p>
                                        </Typography>
                                    </Box>

                                    {/* E) PROFILING */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            e) Profiling
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Profiling means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person's performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements.</p>
                                        </Typography>
                                    </Box>

                                    {/* F) PSEUDONYMISATION */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            f) Pseudonymisation
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Pseudonymisation is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organisational measures to ensure that the personal data are not attributed to an identified or identifiable natural person.</p>
                                        </Typography>
                                    </Box>

                                    {/* G) CONTROLLER OR CONTROLLER RESPONSIBLE FOR THE PROCESSING */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            g) Controller or Controller Responsible for the Processing
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Controller or controller responsible for the processing is the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law.</p>
                                        </Typography>
                                    </Box>

                                    {/* H) PROCESSOR */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            h) Processor
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.</p>
                                        </Typography>
                                    </Box>

                                    {/* I) RECIPIENT */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            i) Recipient
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Recipient is a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing of those data by those public authorities shall be in compliance with the applicable data protection rules according to the purposes of the processing.</p>
                                        </Typography>
                                    </Box>

                                    {/* J) THIRD PARTY */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            j) Third Party
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Third party is a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.</p>
                                        </Typography>
                                    </Box>

                                    {/* K) CONSENT */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            k) Consent
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Consent of the data subject is any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.</p>
                                        </Typography>
                                    </Box>

                                    {/* NAME AND ADDRESS OF THE CONTROLLER */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Name and Address of the Controller
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Consent of the data subject is any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.</p>
                                            <p>Controller for the purposes of the General Data Protection Regulation (GDPR), other data protection laws and other provisions related to data protection is: Provizz Consultants, E96 E-block, Kalkaji, 110019, India, Email: support@virtacc.com, Website: virtacc.com</p>
                                        </Typography>
                                    </Box>

                                    {/* COOKIES */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Cookies
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>The Internet pages of Virtacc use cookies. Cookies are text files that are stored in a computer system via an Internet browser.</p>
                                            <p>Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the dats subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.</p>
                                            <p>Through the use of cookies, Virtacc can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.</p>
                                            <p>By means of a cookie, the information and offers on our website can be optimized with the user in mind. Cookies allow us, as previously mentioned, to recognize our website users. The purpose of this recognition is to make it easier for users to utilize our website. The website user that uses cookies, e.g. does not have to enter access data each time the website is accessed, because this is taken over by the website, and the cookie is thus stored on the user's computer system. Another example is the cookie of a shopping cart in an online shop. The online store remembers the articles that a customer has placed in the virtual shopping cart via a cookie.</p>
                                            <p>The data subject may, at any time, prevent the setting of cookies through our website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be entirely usable.</p>
                                        </Typography>
                                    </Box>

                                    {/* COLLECTION OF GENERAL DATA AND INFORMATION */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Collection of General Data and Information
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>The website of Virtacc collects a series of general data and information when a data subject or automated system calls up the website. This general data and information are stored in the server log files. Collected may be (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system reaches our website (so-called referrers), (4) the sub-websites, (5) the date and time of access to the Internet site, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system, and (8) any other similar data and information that may be used in the event of attacks on our information technology systems.</p>
                                            <p>When using these general data and information, Virtacc does not draw any conclusions about the data subject. Rather, this information is needed to (1) deliver the content of our website correctly, (2) optimize the content of our website as well as its advertisement, (3) ensure the long-term viability of our information technology systems and website technology, and (4) provide law enforcement authorities with the information necessary for criminal prosecution in case of a cyber-attack. Therefore, Virtacc analyzes anonymously collected data and information statistically, with the aim of increasing the data protection and data security of our enterprise, and to ensure an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from all personal data provided by a data subject.</p>
                                        </Typography>
                                    </Box>

                                    {/* REGISTRATION ON OUR WEBSITE */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Registration on our Website
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>The data subject has the possibility to register on the website of the controller with the indication of personal data. Which personal data are transmitted to the controller is determined by the respective input mask used for the registration. The personal data entered by the data subject are collected and stored exclusively for internal use by the controller, and for his own purposes. The controller may request transfer to one or more processors (e.g. a parcel service) that also uses personal data for an internal purpose which is attributable to the controller.</p>
                                            <p>By registering on the website of the controller, the IP address—assigned by the Internet service provider (ISP) and used by the data subject—date, and time of the registration are also stored. The storage of this data takes place against the background that this is the only way to prevent the misuse of our services, and, if necessary, to make it possible to investigate committed offenses. Insofar, the storage of this data is necessary to secure the controller. This data is not passed on to third parties unless there is a statutory obligation to pass on the data, or if the transfer serves the aim of criminal prosecution.</p>
                                            <p>The registration of the data subject, with the voluntary indication of personal data, is intended to enable the controller to offer the data subject contents or services that may only be offered to registered users due to the nature of the matter in question. Registered persons are free to change the personal data specified during the registration at any time, or to have them completely deleted from the data stock of the controller.</p>
                                            <p>The data controller shall, at any time, provide information upon request to each data subject as to what personal data are stored about the data subject. In addition, the data controller shall correct or erase personal data at the request or indication of the data subject, insofar as there are no statutory storage obligations. The entirety of the controller’s employees are available to the data subject in this respect as contact persons.</p>
                                        </Typography>
                                    </Box>

                                    {/* SUBSCRIPTION TO OUR NEWSLETTERS */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Subscription to our Newsletters
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>On the website of Virtacc, users are given the opportunity to subscribe to our enterprise's newsletter. The input mask used for this purpose determines what personal data are transmitted, as well as when the newsletter is ordered from the controller.</p>
                                            <p>Virtacc informs its customers and business partners regularly by means of a newsletter about enterprise offers. The enterprise's newsletter may only be received by the data subject if (1) the data subject has a valid e-mail address and (2) the data subject registers for the newsletter shipping. A confirmation e-mail will be sent to the e-mail address registered by a data subject for the first time for newsletter shipping, for legal reasons, in the double opt-in procedure. This confirmation e-mail is used to prove whether the owner of the e-mail address as the data subject is authorized to receive the newsletter.</p>
                                            <p>During the registration for the newsletter, we also store the IP address of the computer system assigned by the Internet service provider (ISP) and used by the data subject at the time of the registration, as well as the date and time of the registration. The collection of this data is necessary in order to understand the (possible) misuse of the e-mail address of a data subject at a later date, and it therefore serves the aim of the legal protection of the controller.</p>
                                            <p>The personal data collected as part of a registration for the newsletter will only be used to send our newsletter. In addition, subscribers to the newsletter may be informed by e-mail, as long as this is necessary for the operation of the newsletter service or a registration in question, as this could be the case in the event of modifications to the newsletter offer, or in the event of a change in technical circumstances. There will be no transfer of personal data collected by the newsletter service to third parties. The subscription to our newsletter may be terminated by the data subject at any time. The consent to the storage of personal data, which the data subject has given for shipping the newsletter, may be revoked at any time. For the purpose of revocation of consent, a corresponding link is found in each newsletter. It is also possible to unsubscribe from the newsletter at any time directly on the website of the controller, or to communicate this to the controller in a different way.</p>
                                        </Typography>
                                    </Box>


                                </Box>
                            </Grid>

                        </Container>
                    </Box>
                </PageContainer>
            </MainLayout>
        </React.Fragment>
    )
}

export default PrivacyPolicy