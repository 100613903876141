import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from 'dayjs';
import { IntegrationData } from '../../../../interfaces/Integration';

// const statusMap = {
//   pending: { label: 'Pending', color: 'warning' },
//   delivered: { label: 'Delivered', color: 'success' },
//   refunded: { label: 'Refunded', color: 'error' },
// } as const;

export interface ChannelProps {
  sx?: SxProps;
  channels?: IntegrationData[];
}

function changeStatus() {
  // console.log('clicked');
}

const ChannelsTable: React.FC<ChannelProps> = ({ channels = [], sx }) => {
  return (
    <Card sx={sx}>
      <CardHeader title="Channels" />
      <Divider />
      <Box sx={{ overflowX: 'auto' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Organization</TableCell>
              <TableCell>Channel Type</TableCell>
              <TableCell>Store Name</TableCell>
              <TableCell>Refresh Token</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {channels.map((channel) => {
            //   const { label, color } = statusMap[order.status] ?? { label: 'Unknown', color: 'default' };

              return (
                <TableRow hover key={channel.organization}>
                  <TableCell>{channel.organization}</TableCell>
                  <TableCell>{channel.ChannelType}</TableCell>
                  <TableCell>Demo Name</TableCell>
                  <TableCell>{channel.RefreshToken}</TableCell>
                  <TableCell>{dayjs(channel.createdAt).format('MMM D, YYYY')}</TableCell>
                  <TableCell><Chip component={Button} onClick={changeStatus} color='success' label='Active' /></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={<ArrowForwardIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
      {/* <TablePagination 
        component="div"
        count={count}
        onPageChange={noop}
        onRowsPerPageChange={noop}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
}

export default ChannelsTable;