import { Button, Stack } from '@mui/material'
import React from 'react'
import { paths } from '../../../../paths'

const DashboardNavigation: React.FC = () => {
    const toDashboard = () => window.location.href = paths.dashboard.overview;
    return (
        <Stack direction={'row'} spacing={2} sx={{ '& button:first-child': { mr: 2 } }}>
            <Button onClick={toDashboard} variant="outlined">
                Dashboard
            </Button>
        </Stack>
    )
}

export default DashboardNavigation