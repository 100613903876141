import { Box, Container, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { Stats } from '../../../interfaces/Stats'
import { statsData } from './StatsData'

interface StatsItemProps {
  item: Stats
}

const StatsItem: FC<StatsItemProps> = ({ item }) => {
  const { value, label, icon } = item
  const Icon = icon;
  return (
    <Box sx={{ textAlign: 'center', mb: { xs: 1, md: 0 } }}>
      <Box>
        <Icon sx={{ fontSize: { xs: 50, md: 70 } }} />
      </Box>
      <Typography
        sx={{ color: 'secondary.main', mb: { xs: 1, md: 2 }, fontSize: { xs: 34, md: 40 }, fontWeight: 'bold' }}
      >
        {value}
      </Typography>
      <Typography color="text.secondary" variant="h6">
        {label}
      </Typography>
    </Box>
  )
}

const HomeStats: FC = () => {
  return (
    <>
      <Box id="stats" sx={{ backgroundColor: 'background.paper', position: 'relative', pt: 4, pb: { xs: 8, md: 10 } }}>
        <Container maxWidth="lg">
          {/* Experience */}
          <Box sx={{ boxShadow: 20, py: 4, px: 7, borderRadius: 4, backgroundColor: 'background.level1' }}>
            <Grid container spacing={2}>
              {statsData.map((item) => (
                <Grid key={item.label} item xs={6} md={3}>
                  <StatsItem item={item} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default HomeStats