import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const HomeGetStarted: FC = () => {
    return (
        <Box id="#testimonial" sx={{ pb: { xs: 6, md: 10 }, backgroundColor: 'background.paper' }}>
            <Container>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            component="h2"
                            sx={{
                                position: 'relative',
                                fontSize: { xs: 36, md: 46 },
                                mt: { xs: 0, md: 7 },
                                mb: 4,
                                lineHeight: 1,
                                fontWeight: 'bold',
                            }}
                        >
                            Ready to{' '}
                            <Typography
                                component="mark"
                                sx={{
                                    position: 'relative',
                                    color: 'primary.main',
                                    fontSize: 'inherit',
                                    fontWeight: 'inherit',
                                    backgroundColor: 'unset',
                                }}
                            >
                                Get Started{' '}
                            </Typography>
                            ?
                        </Typography>
                            <Box sx={{ mb: 2 }}>
                                <Typography  variant="h5" sx={{ mb: 2, color: 'text.secondary' }}>
                                    Start your project with a 14-day free trial
                                </Typography>
                                <Button variant='contained' endIcon={<ArrowForwardIcon />} >
                                    Get Started
                                </Button>
                            </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Box 
                            component={"img"}
                            src='/assets/section/section-bg-dark.png'
                            width={"100%"}
                            alt='Get Started img'
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default HomeGetStarted