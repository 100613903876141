import * as React from 'react';
import Layout from '../Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';
import { Box, Tabs, Stack, Typography, Tab } from '@mui/material';
import Notifications from '../../../components/Dashboard/Settings/Notifications';
import UpdatePasswordForm from '../../../components/Dashboard/Settings/UpdatePasswordForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Settings: React.FC = () => {
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <PageContainer title={ 'Settings | Dashboard | ' + config.site.name} description='This is Settings page of Virtacc'>
      <Layout>
          <Stack spacing={3}>
            <Typography variant="h4">Settings</Typography>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Subscription" {...a11yProps(1)} />
                  <Tab label="Members" {...a11yProps(3)} />
                  <Tab label="Advanced" {...a11yProps(4)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Stack spacing={3}>
                  <Notifications />
                  <UpdatePasswordForm />
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                Item Two
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Item Three
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                Item Four
              </CustomTabPanel>
            </Box>
          </Stack>
      </Layout>
    </PageContainer>
  );
}

export default Settings
