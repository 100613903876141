import React from 'react'
import { useLocation } from 'react-router-dom'

const Verify: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const amazon_callback_uri = queryParams.get('amazon_callback_uri');
  const amazon_state = queryParams.get('amazon_state');
  const version = queryParams.get('version');
  const selling_partner_id = queryParams.get('selling_partner_id');
  return (
    <>
      <div>Verify</div>
      <div>Callback URI = {amazon_callback_uri}</div>
      <div>State = {amazon_state}</div>
      <div>Version = {version}</div>
      <div>SP ID = {selling_partner_id}</div>

    </>
  )
}

export default Verify