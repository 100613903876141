import { CustomFetch } from "../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const getAllOrganizations = async () => {
    const response = await CustomFetch(API_URL + '/organizations', { method: 'GET'});
    return response.json();
}

const createOrganization = async ( name: string, owner: string, phone: string, email: string) => {
    return await CustomFetch( API_URL + "/organizations", {
        method: "POST",
        body: JSON.stringify({ 
            name: name, 
            owner: owner, 
            phone: phone,
            email: email
        }),
  });
}

const organizationService = {
    getAllOrganizations,
    createOrganization
}

export default organizationService;