import { Upload } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, FormEvent, useState } from 'react'
import { toast } from 'react-toastify';

type Platform = 'amazon' | 'flipkart' | 'jio' | 'myntra' | 'meesho' | 'snapdeal';

const UploadFileForm: React.FC = () => {

    const [files, setFiles] = useState<Record<Platform, File | null>>({
        amazon: null,
        flipkart: null,
        jio: null,
        myntra: null,
        meesho: null,
        snapdeal: null,
    });

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>, platform: Platform) => {
        if (event.target.files && event.target.files.length > 0) {
            setFiles({ ...files, [platform]: event.target.files[0] });
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(event.target)
        // Add your form submission logic here
        // For example, uploading files to a server
        toast.success('Files uploaded successfully!');
    };

    const fileInputId = (platform: Platform) => `${platform}-file`;

    return (
        <Stack sx={{ my: 3, width: '100%' }} spacing={3}>
            <Typography sx={{ mb: 3 }} variant="h5">Upload Excel Files</Typography>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {['amazon', 'flipkart', 'jio', 'myntra', 'meesho', 'snapdeal'].map((platform) => (
                        <div key={platform}>
                            <TextField
                                label={`Upload ${platform.charAt(0).toUpperCase() + platform.slice(1)} File`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <input
                                                id={fileInputId(platform as Platform)}
                                                name={fileInputId(platform as Platform)}
                                                type="file"
                                                accept=".xls,.xlsx"
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleFileChange(e, platform as Platform)}
                                                // required
                                            />
                                            <label htmlFor={fileInputId(platform as Platform)}>
                                                <IconButton color="primary" component="span">
                                                    <Upload />
                                                </IconButton>
                                            </label>
                                        </InputAdornment>
                                    ),
                                }}
                                value={files[platform as Platform]?.name || ''}
                                required
                            />
                        </div>
                    ))}
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                    >
                        Upload
                    </Button>
                </Stack>
            </form>
        </Stack>
    );
}

export default UploadFileForm