import { Button, Stack, TextField } from "@mui/material";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import { toast } from "react-toastify";
import organizationService from "../../../templates/Organizations/api/Organization.api";

interface AddOrganizationFormProps {
    onSuccess: () => void; // Add this prop
}

const AddOrganizationForm: FC<AddOrganizationFormProps> = ({ onSuccess }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState({
        name: '',
        owner: '',
        phone: '',
        email: ''
    });

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await organizationService.createOrganization(
                formData.name,
                formData.owner,
                formData.phone,
                formData.email
            );
            if (response.ok) {
                toast.success('Organization added successfully!');
                onSuccess(); // Call the onSuccess callback
                setFormData({ name: '', owner: '', phone: '', email: '' }); // Clear the form data
            }
            setLoading(false);
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
            setLoading(false);
        }
    };

    return (
        <Stack sx={{ my: 3 }}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        name="name"
                        type="text"
                        label="Organization Name"
                        value={formData.name}
                        onChange={onChange}
                        required
                    />
                    <TextField
                        name="owner"
                        type="text"
                        label="Organization Owner"
                        value={formData.owner}
                        onChange={onChange}
                        required
                    />
                    <TextField
                        name="phone"
                        type="text"
                        label="Phone Number"
                        value={formData.phone}
                        onChange={onChange}
                        required
                    />
                    <TextField
                        name="email"
                        type="email"
                        label="Email Address"
                        value={formData.email}
                        onChange={onChange}
                        required
                    />
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={loading}
                    >
                        Add
                    </Button>
                </Stack>
            </form>
        </Stack>
    );
};

export default AddOrganizationForm;