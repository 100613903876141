import { Helper } from "../Helper";

// HeadersUtility.ts
export const getCustomHeaders = (): Headers => {

    const headers = new Headers({
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'true',
    });

    const token = Helper.getStorageData('userToken');
    const userData = Helper.getStorageData('user');

    // Safely parse JSON if the data exists and is not an empty string
    const parsedToken = token ? JSON.parse(token) : null;
    const parsedUserData = userData ? JSON.parse(userData) : null;


    if (parsedUserData && parsedUserData.organization) {
        headers.set('x-tenant-name', parsedUserData.organization);
    }

    if (parsedToken) {
        headers.set('x-access-token', parsedToken);
    }

    return headers;
};
