'use client';

import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import { Helper } from '../../../utils/Helper';
import { UserPayload } from '../../../interfaces/UserPayload';

const states = [
    { value: 'alabama', label: 'Alabama' },
    { value: 'new-york', label: 'New York' },
    { value: 'san-francisco', label: 'San Francisco' },
    { value: 'los-angeles', label: 'Los Angeles' },
] as const;

const AccountDetailsForm: React.FC = () => {

    const [user, setUser] = React.useState<UserPayload>({ email: '', role: 1, organization: ''});

    React.useEffect(() => {
        const currentUser: UserPayload = Helper.getCurrentUser();
        setUser(currentUser);
    }, []);

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
            }}
        >
            <Card>
                <CardHeader subheader="The information can be edited" title="Profile" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid md={6} xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel>First name</InputLabel>
                                <OutlinedInput value={user.email} label="First name" name="firstName" />
                            </FormControl>
                        </Grid>
                        <Grid md={6} xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel>Organization</InputLabel>
                                <OutlinedInput value={user.organization} label="Organization" name="organizationName" />
                            </FormControl>
                        </Grid>
                        <Grid md={6} xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel>Email address</InputLabel>
                                <OutlinedInput value={user.email} label="Email address" name="email" />
                            </FormControl>
                        </Grid>
                        <Grid md={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Phone number</InputLabel>
                                <OutlinedInput label="Phone number" name="phone" type="tel" />
                            </FormControl>
                        </Grid>
                        <Grid md={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>State</InputLabel>
                                <Select defaultValue="New York" label="State" name="state" variant="outlined">
                                    {states.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>City</InputLabel>
                                <OutlinedInput label="City" />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button type="submit" variant="contained">Save details</Button>
                </CardActions>
            </Card>
        </form>
    );
}

export default AccountDetailsForm;