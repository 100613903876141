export function isAuthenticated() {
    const userToken = localStorage.getItem('userToken');
    const user = localStorage.getItem('user');
    return userToken && user;
}

export function isSuperAdmin() {
    const userToken = localStorage.getItem('userToken');
    const user = localStorage.getItem('user');
    if (user && userToken) {
        let userData = JSON.parse(user);
        return (userData.role === 0);
    }
}