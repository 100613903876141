import React from "react";
import MainLayout from "./Layout";
import HomeHero from "./Hero/Hero";
// import HomePopularCourse from "./Services/PopularCourse";
import HomeTestimonial from "./Testimonial/Testimonial";
// import HomeFeature from "./Features/Features";
// import HomeOurTeam from "./Team/Team";
import HomeNewsLetter from "./NewsLetter/NewsLetter";
import HomeStats from "./Stats/Stats";
// import HomeFAQs from "./FAQs/FAQs";
import HomeGetStarted from "./GetStarted/GetStarted";
import HomeContactUs from "./ContactUs/ContactUs";
import FEAT12 from "./Features/FEAT12";
import HomeFAQsNNNN from "./FAQs/FAQn";
// import BaseHomeHero from "./Hero/BaseHero";

const Home: React.FC = () => {
    return (
        <MainLayout>
            <HomeHero />
            <HomeStats />
            <FEAT12 />
            {/* <BaseHomeHero /> */}
            {/* <HomePopularCourse /> */}
            {/* <HomeFeature /> */}
            <HomeTestimonial />
            {/* <HomeOurTeam /> */}
            {/* <HomeFAQs /> */}
            <HomeFAQsNNNN />
            <HomeGetStarted />
            <HomeContactUs />
            <HomeNewsLetter />
        </MainLayout>
    );
}

export default Home;
