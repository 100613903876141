import React from 'react'
import Layout from '../Layout'
import { Typography } from '@mui/material'

const Billing: React.FC = () => {
  return (
    <Layout>
      <Typography variant="h4">Billing</Typography>
    </Layout>
  )
}

export default Billing