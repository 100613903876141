import { Box, Container, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import ContactUsForm from '../../../components/Home/ContactUs/ContactUsForm'

const HomeContactUs: FC = () => {
  return (
    <Box id="#contact-us" sx={{ py: { xs: 10, md: 14 }, backgroundColor: 'background.paper' }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box component={"img"} src='/assets/section/section-bg-dark.png' width={"100%"} sx={{ position: 'relative' }} />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography
              component="h2"
              sx={{
                position: 'relative',
                fontSize: { xs: 40, md: 50 },
                ml: { xs: 0, md: 4 },
                mt: 2,
                mb: 3,
                lineHeight: 1,
                fontWeight: 'bold',
              }}
            >
              Let's{' '}
              <Typography
                component="mark"
                sx={{
                  position: 'relative',
                  color: 'primary.main',
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  backgroundColor: 'unset',
                }}
              >
                work together
              </Typography>
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 2, ml: { xs: 0, md: 4 } }}>
              Any question or remark? Just write us a message
            </Typography>

            <Grid container spacing={2}  sx={{ ml: { xs: -2, md: 2 } }} >
              <Grid item >
                <Box sx={{ 
                  px: 2, py: 1.5, boxShadow: 20, borderRadius: 4, 
                  display: 'flex', alignItems: 'center', 
                  backgroundColor: 'background.level1'
                }}>
                  <ContactUsForm />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default HomeContactUs