import React from 'react'
import { Typography } from '@mui/material'
import Layout from '../Layout'
import UploadFileForm from '../../../components/Dashboard/Upload/UploadFileForm'

const Upload: React.FC = () => {
  // function handleSubmit(event: FormEvent<HTMLFormElement>): void {
  //   throw new Error('Function not implemented.')
  // }

  // function conSole () : void {
  //   console.log('Clicked');
  // }

  return (
    <Layout>
      <Typography variant="h4">Upload</Typography>
      <UploadFileForm />
    </Layout>
  )
}

export default Upload