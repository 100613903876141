import * as React from 'react';
// import type { Metadata } from 'next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Layout from '../Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';

const SettingsOrganizations: React.FC = () => {
  return (
    <PageContainer title={ 'Settings | Dashboard | ' + config.site.name} description='This is Settings page of Virtacc'>
      <Layout>
          <Stack spacing={3}>
            <Typography variant="h4">Settings</Typography>
          </Stack>
      </Layout>
    </PageContainer>
  );
}

export default SettingsOrganizations
