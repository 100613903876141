import React, { useEffect, useState } from "react";
import organizationService from "./api/Organization.api";
import { OrganizationsData } from "../../interfaces/Organization";
import PageContainer from "../../components/PageContainer/PageContainer";
import { config } from "../../config";
import { Stack, Typography, Button, Modal, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Layout from "./Layout";
import AllOrganizations from "../../components/Organization/Overview/AllOrganizations";
import AddOrganizationForm from "../../components/Organization/Overview/AddOrganizationForm";
import { Close } from "@mui/icons-material";

const Organizations: React.FC = () => {
    const [organizationData, setOrganizationData] = useState<OrganizationsData[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        getOrganisation();
    }, []);

    const getOrganisation = async () => {
        let organizationData = await organizationService.getAllOrganizations();
        if (organizationData.data) setOrganizationData(organizationData.data);
    };

    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);

    const handleFormSuccess = () => {
        handleModalClose(); // Close the modal
        getOrganisation();  // Refresh the organization data
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid var(--mui-palette-primary-main)',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4,
    };

    const renderModal = (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="modal-add-organization"
            aria-describedby="modal-add-organization-description"
        >
            <Stack sx={style}>
                <Stack direction="row" spacing={3}>
                    <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
                        <Typography sx={{ mb: 3 }} variant="h5">Add Organization</Typography>
                    </Stack>
                    <IconButton
                        aria-label="close"
                        onClick={handleModalClose}
                        sx={{ position: 'absolute', right: 20 }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <AddOrganizationForm onSuccess={handleFormSuccess} />
            </Stack>
        </Modal>
    );

    return (
        <PageContainer title={"Overview | Organizations | " + config.site.name} description='This is Organization page of Virtacc'>
            <Layout>
                <Stack spacing={3}>
                    <Stack direction="row" spacing={3}>
                        <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
                            <Typography variant="h4">Overview</Typography>
                        </Stack>
                        <Button onClick={handleModalOpen} startIcon={<AddIcon />} variant="contained">
                            Add
                        </Button>
                        {renderModal}
                    </Stack>
                    <AllOrganizations
                        organizations={organizationData}
                        sx={{ height: '100%' }}
                    />
                </Stack>
            </Layout>
        </PageContainer>
    );
};

export default Organizations;
