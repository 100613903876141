interface reportList {
    title: string,
    description: string,
    link: string
}

export const data: reportList[] = [
    {
        title: 'Dashboard by day',
        description: 'Contains all the data from the "More" menu (on the Dashboard page), broken down by days.',
        link: ''
    },
    {
        title: 'Dashboard by month',
        description: 'Contains all the data from the "More" menu (on the Dashboard page), broken down by months.',
        link: ''
    },
    {
        title: 'Dashboard by product',
        description: 'Contains all the data from the "More" menu (on the Dashboard page), broken down by days and products.',
        link: ''
    },
    {
        title: 'Orders',
        description: 'Contains your orders, with information about products, units, coupon codes, etc.',
        link: ''
    },
    {
        title: 'Sales by product/month',
        description: 'Sales data, broken down by months and products. Analyze the sales dynamic, quickly identify trends to react on time on products, which need your attention',
        link: ''
    },
    {
        title: 'Advertising Performance Report',
        description: "The report analyzes the effectiveness of a company's advertising campaigns, and contains advertising performance parameters, such as: Conversion Rate, CPC, TACOS, ROAS, PPC ACOS etc.",
        link: ''
    },
    {
        title: 'Repeat customers (by brand)',
        description: 'The report shows the share of orders by repeat customers. Repeat customer is a customer, who ordered the same brand more than once within the selected period.',
        link: ''
    },
    {
        title: 'Stock history report',
        description: 'The report shows the inventory level of stock on a specified date by product and marketplace.',
        link: ''
    },
]