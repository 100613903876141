// Dashboard.tsx
import { Stack } from "@mui/material";
import dayjs from 'dayjs';
import Grid from "@mui/material/Unstable_Grid2";
import Layout from "./Layout";
import { Budget } from "../../components/Dashboard/Overview/Budget";
import TotalCustomers from "../../components/Dashboard/Overview/TotalCustomer";
import React from "react";
import TasksProgress from "../../components/Dashboard/Overview/TaskProgress";
import TotalProfit from "../../components/Dashboard/Overview/TotalProfit";
import Sales from "../../components/Dashboard/Overview/Sales";
import PageContainer from "../../components/PageContainer/PageContainer";
import { config } from "../../config";
import Traffic from "../../components/Dashboard/Overview/Traffic";
import LatestOrders from "../../components/Dashboard/Overview/LatestOrders";

const Dashboard: React.FC = () => {

  return (
    <PageContainer title= { "Overview | Dashboard | " + config.site.name} description='This is Overview page of Virtacc'>
      <Layout>
          <Stack spacing={5}>
            {/* <Typography variant="h4">Overview</Typography> */}
            <Grid container spacing={3}>
              <Grid lg={3} sm={6} xs={12}>
                <Budget diff={12} trend="up" sx={{ height: '100%' }} value="₹24,654" />
              </Grid>
              <Grid lg={3} sm={6} xs={12}>
                <TotalCustomers diff={16} trend="down" sx={{ height: '100%' }} value="1.6k" />
              </Grid>
              <Grid lg={3} sm={6} xs={12}>
                <TasksProgress sx={{ height: '100%' }} value={75.5} />
              </Grid>
              <Grid lg={3} sm={6} xs={12}>
                <TotalProfit sx={{ height: '100%' }} value="₹15k" />
              </Grid>
              <Grid lg={8} xs={12}>
                <Sales
                  chartSeries={[
                    { label: 'This year', data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20] },
                    { label: 'Last year', data: [12, 11, 4, 6, 2, 9, 9, 10, 11, 12, 13, 13] },
                  ]}
                  xAxis={{data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], scaleType: 'band'}}
                  sx={{ height: '100%' }}
                />
              </Grid>
              <Grid lg={4} md={6} xs={12}>
                <Traffic 
                  data={[
                    {label : 'Tablet', value: 63},
                    {label : 'Phone', value: 73},
                    {label : 'Desktop', value: 163}
                  ]} 
                  sx={{ height: '100%' }} 
                />
              </Grid>
              <Grid lg={4} md={6} xs={12}>
                <Budget diff={12} trend="up" sx={{ height: '100%' }} value="₹24k" />
              </Grid>
              <Grid lg={8} md={12} xs={12}>
              <LatestOrders
                orders={[
                  {
                    id: 'ORD-007',
                    customer: { name: 'Ekaterina Tankova' },
                    amount: 30.5,
                    status: 'pending',
                    createdAt: dayjs().subtract(10, 'minutes').toDate(),
                  },
                  {
                    id: 'ORD-006',
                    customer: { name: 'Cao Yu' },
                    amount: 25.1,
                    status: 'delivered',
                    createdAt: dayjs().subtract(10, 'minutes').toDate(),
                  },
                  {
                    id: 'ORD-004',
                    customer: { name: 'Alexa Richardson' },
                    amount: 10.99,
                    status: 'refunded',
                    createdAt: dayjs().subtract(10, 'minutes').toDate(),
                  },
                  {
                    id: 'ORD-003',
                    customer: { name: 'Anje Keizer' },
                    amount: 96.43,
                    status: 'pending',
                    createdAt: dayjs().subtract(10, 'minutes').toDate(),
                  },
                  {
                    id: 'ORD-002',
                    customer: { name: 'Clarke Gillebert' },
                    amount: 32.54,
                    status: 'delivered',
                    createdAt: dayjs().subtract(10, 'minutes').toDate(),
                  },
                  {
                    id: 'ORD-001',
                    customer: { name: 'Adam Denisov' },
                    amount: 16.76,
                    status: 'delivered',
                    createdAt: dayjs().subtract(10, 'minutes').toDate(),
                  },
                ]}
                sx={{ height: '100%' }}
              />
              </Grid>
            </Grid>
          </Stack>
      </Layout>
    </PageContainer>
  );
}

export default Dashboard;
