import * as React from 'react';
// import type { Metadata } from 'next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Layout from '../Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';
import Header from '../../Practice/heaser';

const CustomersOrganizations: React.FC = () => {
  return (
    <PageContainer title={ 'Customers | Organizations | ' + config.site.name} description='This is Customers page of Virtacc'>
      <Layout>
          <Stack spacing={3}>
            <Typography variant="h4">Customers</Typography>
            <Header />
          </Stack>
      </Layout>
    </PageContainer>
  );
}

export default CustomersOrganizations;
