import { Button, Stack, TextField } from "@mui/material";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import { toast } from "react-toastify";
import ProductService from "./api/Products.api";

interface AddProductFormProps {
    onSuccess: () => void; // Add this prop
}

const AddProductForm: FC<AddProductFormProps> = ({ onSuccess }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState({
        SellerSKU: '',
        ASIN: '',
        FnSKU: '',
        LastUpdated: '',
        ProductName: '',
        TotalQuantity: 0,
        Marketplace: '',
        Condition: '',
    });

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await ProductService.createProduct(
                formData.SellerSKU,
                formData.ASIN,
                formData.FnSKU,
                formData.LastUpdated,
                formData.ProductName,
                formData.TotalQuantity,
                formData.Marketplace,
                formData.Condition,
            );
            if (response.ok) {
                toast.success('Organization added successfully!');
                onSuccess(); // Call the onSuccess callback
                setFormData({
                    SellerSKU: '', ASIN: '', FnSKU: '', LastUpdated: '',
                    ProductName: '', TotalQuantity: 0, Marketplace: '', Condition: '',
                }); // Clear the form data
            }
            setLoading(false);
        } catch (error) {
            toast.error('Something went wrong! Please try again.');
            setLoading(false);
        }
    };

    return (
        <Stack sx={{ my: 3 }}>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        name="ProductName"
                        type="text"
                        label="Product Name"
                        value={formData.ProductName}
                        onChange={onChange}
                        required
                    />
                    <TextField
                        name="SellerSKU"
                        type="text"
                        label="Seller SKU"
                        value={formData.SellerSKU}
                        onChange={onChange}
                        required
                    />
                    <TextField
                        name="FnSKU"
                        type="text"
                        label="FnSKU"
                        value={formData.FnSKU}
                        onChange={onChange}
                        required
                    />
                    <TextField
                        name="ASIN"
                        type="text"
                        label="ASIN"
                        value={formData.ASIN}
                        onChange={onChange}
                        required
                    />
                    <TextField
                        name="Condition"
                        type="text"
                        label="Condition"
                        value={formData.Condition}
                        onChange={onChange}
                        required
                    />
                    <TextField
                        name="TotalQuantity"
                        type="text"
                        label="TotalQuantity"
                        value={formData.TotalQuantity}
                        onChange={onChange}
                        required
                    />
                    <TextField
                        name="Marketplace"
                        type="text"
                        label="Marketplace"
                        value={formData.Marketplace}
                        onChange={onChange}
                        required
                    />
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={loading}
                    >
                        Add
                    </Button>
                </Stack>
            </form>
        </Stack>
    );
};

export default AddProductForm;