'use client';

import * as React from 'react';
import { Typography, Stack, CardHeader, CardContent, Card, Box } from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import ComputerIcon from '@mui/icons-material/Computer';
import TabletIcon from '@mui/icons-material/Tablet';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

const iconMapping = { 'Desktop': ComputerIcon, 'Tablet': TabletIcon, 'Phone': SmartphoneIcon } as Record<string, OverridableComponent<SvgIconTypeMap<{}, "svg">>>;

export interface TrafficProps {
  data: { label : string, value : number}[];
  sx?: SxProps;
}

const Traffic: React.FC<TrafficProps> = ({ data, sx }) => {

  return (
    <Card sx={sx}>
      <CardHeader title="Traffic source" />
      <CardContent>
        <Stack
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            width: '100%', 
            height: '100%' 
          }}
        >
          <Box sx={{ alignItems: 'center', justifyContent: 'center'}} >
            <PieChart 
              series={[
                {
                  data: data,
                  innerRadius: 50,
                  outerRadius: 100,
                  paddingAngle: 5,
                  cornerRadius: 5,
                }
              ]}
              height={300}
              width={300}  // Explicitly set the width to ensure proper fitting
              slotProps={{
                legend: { hidden: true },
              }}
            />
          </Box>
          <Stack 
            direction="row" 
            spacing={2} 
            sx={{ 
              alignItems: 'center', 
              justifyContent: 'center', 
              width: '100%' 
            }}
          >
            {
              data.map((item) => {
                const { label, value } = item;
                const IconComponent = iconMapping[label];

                return (
                  <Stack 
                    spacing={1} 
                    sx={{ 
                      alignItems: 'center', 
                      justifyContent: 'center' 
                    }}
                  >
                    {IconComponent ? <IconComponent /> : null}
                    <Typography variant="h6">{label}</Typography>
                    <Typography color="text.secondary" variant="subtitle2"> {value}% </Typography>
                  </Stack>
                )
              })
            }
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Traffic;
