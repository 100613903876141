'use client';

import * as React from 'react';
import Box from '@mui/material/Box';
import { useColorScheme } from '@mui/material/styles';
// import { Typography } from '@mui/material';

// import { NoSsr } from '@/components/core/no-ssr';

const HEIGHT = 60;
const WIDTH = 60;

type Color = 'dark' | 'light';

export interface LogoProps {
  color?: Color;
  emblem?: boolean;
  height?: number;
  width?: number;
}

export function Logo({ color = 'dark', emblem, height = HEIGHT, width = WIDTH }: LogoProps): React.JSX.Element {
  let url: string;

  if (emblem) {
    url = color === 'light' ? '/assets/logo/virtacc/logo-demo-white.png' : '/assets/logo/virtacc/logo-demo-white.png';
    // url = color === 'light' ? '/assets/logo-virtacc-light.png' : '/assets/logo-virtacc-dark.png';
    // url = color === 'light' ? '/assets/logo-emblem.svg' : '/assets/logo-emblem--dark.svg';
  } else {
    url = color === 'light' ? '/assets/logo/virtacc/logo-demo-white-copy.png' : '/assets/logo/virtacc/logo-demo-white-copy.png';
    // url = color === 'light' ? '/assets/logo-virtacc-light.png' : '/assets/logo-virtacc-dark.png';
    // url = color === 'light' ? '/assets/logo.svg' : '/assets/logo--dark.svg';
  }

  return <Box alt="logo" component="img" height={height} src={url} width={width} />;
  // return <Box height={height} width={width} >
  //   <Typography sx={{ color: "secondary.main", fontSize: 30, fontWeight: 'bold' }}> Virtacc </Typography>   
  // </Box>
}

export interface DynamicLogoProps {
  colorDark?: Color;
  colorLight?: Color;
  emblem?: boolean;
  height?: number;
  width?: number;
}

const DynamicLogo: React.FC<DynamicLogoProps> = ({
  colorDark = 'light',
  colorLight = 'dark',
  height = HEIGHT,
  width = WIDTH,
  ...props
}) => {
  const { colorScheme } = useColorScheme();
  const color = colorScheme === 'dark' ? colorDark : colorLight;

  return (
    // <NoSsr fallback={<Box sx={{ height: `${height}px`, width: `${width}px` }} />}>
      <Logo color={color} height={height} width={width} {...props} />
    // </NoSsr>
  );
}

export default DynamicLogo;