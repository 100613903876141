import { Stats } from "../../../interfaces/Stats";
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';

export const statsData: Array<Stats> = [
    {
        label: 'Query Solved',
        value: '1125K+',
        icon: QueryStatsOutlinedIcon
    },
    {
        label: 'Accounting Done',
        value: '1453K+',
        icon: AccountBalanceOutlinedIcon
    },
    {
        label: 'Happy Customers',
        value: '3790+',
        icon: SentimentVerySatisfiedOutlinedIcon
    },
    {
        label: 'R2R Reports',
        value: '9239+',
        icon: SummarizeOutlinedIcon
    },
]