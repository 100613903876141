'use client';

import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import type { SxProps } from '@mui/material/styles';
import SyncIcon from '@mui/icons-material/Sync';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BarChart } from '@mui/x-charts/BarChart';

export interface SalesProps {
    chartSeries: { label: string; data: number[] }[];
    sx?: SxProps;
    xAxis: {data: string[], scaleType: 'band'}
}
const Sales: React.FC<SalesProps> = ({ chartSeries, sx, xAxis }) => {

    return (
        <Card sx={sx}>
            <CardHeader
                action={
                    <Button color="inherit" size="small" startIcon={<SyncIcon />}>
                        Sync
                    </Button>
                }
                title="Sales"
            />
            <CardContent>
                <BarChart
                    xAxis={[xAxis]}
                    series={chartSeries}
                    height={350}
                />
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button color="inherit" endIcon={<ArrowForwardIcon />} size="small">
                    Overview
                </Button>
            </CardActions>
        </Card>
    );
}

export default Sales;