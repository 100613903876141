import { Button, Stack, TextField, 
    // Select, FormControl, InputLabel, MenuItem 
} from "@mui/material";
// import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import IntegrationService from "./api/Integration.api";
import { toast } from "react-toastify";

interface ConnectIntegrationFormProps {
    onSuccess: () => void; // Add this prop
}

const ConnectIntegrationForm: FC<ConnectIntegrationFormProps> = ({ onSuccess }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState({
        organization: '',
        ChannelType: '',
        RefreshToken: '',
    });

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const onSelectChange = ( event: SelectChangeEvent ) => {
    //     const { name, value } = event.target;
    //     setFormData({ ...formData, [name]: value })
    // }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await IntegrationService.createIntegration(formData);
            if (response.ok) {
                toast.success('Organization added successfully!');
                onSuccess(); // Call the onSuccess callback
                setFormData({
                    organization: '',
                    ChannelType: '',
                    RefreshToken: '',
                });
            }
            setLoading(false);
        } catch (error) {
            toast.error('Something went wrong! Please try again.')
            setLoading(false);
        }

    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
                {/* <FormControl>
                    <InputLabel>Integration</InputLabel>
                    <Select
                        name="name"
                        value={formData.name}
                        onChange={onSelectChange}
                        label="Integration"
                        variant="outlined"
                        required
                    >
                        <MenuItem value={"Amazon"}>Amazon</MenuItem>
                        <MenuItem value={"Flipkart"}>Flipkart</MenuItem>
                        <MenuItem value={"Myntra"}>Myntra</MenuItem>
                        <MenuItem value={"Meesho"}>Meesho</MenuItem>
                        <MenuItem value={"Snapdeal"}>Snapdeal</MenuItem>
                        <MenuItem value={"JioMart"}>JioMart</MenuItem>
                    </Select>
                </FormControl> */}
                {/* <TextField 
                    name="name" 
                    type="text"
                    label="Integration" 
                    value={formData.name}
                    onChange={onChange}
                    required
                /> */}
                <TextField
                    name="organization"
                    type="text"
                    label="Organization"
                    value={formData.organization}
                    onChange={onChange}
                    required
                />
                <TextField
                    name="ChannelType"
                    type="text"
                    label="Channel Type"
                    value={formData.ChannelType}
                    onChange={onChange}
                    required
                />
                <TextField
                    name="RefreshToken"
                    type="text"
                    label="RefreshToken"
                    value={formData.RefreshToken}
                    onChange={onChange}
                    required
                />
                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={loading}
                >
                    Connect
                </Button>
            </Stack>
        </form>
    );
};

export default ConnectIntegrationForm;