// 'use client';

// import * as React from 'react';
// import { Link as RouterLink, useLocation } from 'react-router-dom';
// import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

// import { isNavItemActive } from '../../../utils/IsNavItemActive';
// import { navItems } from './config';
// import { navIcons } from './NavIcons';
// import { paths } from '../../../paths';
// import { Logo } from '../../Core/Logo';
// import { Helper } from '../../../utils/Helper';
// import { UserPayload } from '../../../interfaces/UserPayload';
// import { NavItemConfig } from '../../../interfaces/NavItemConfig';

// // const User: UserPayload = Helper.getCurrentUser();

// const SideNav: React.FC = () => {
//   const location = useLocation();

//   const [User, setUser] = React.useState<UserPayload>({ email: '', role: 1, organization: ''});
//   React.useEffect(() => {
//     const User = Helper.getCurrentUser();
//     setUser(User);
//   },[]);

//   return (
//     <Box
//       sx={{
//         '--SideNav-background': 'var(--mui-palette-neutral-950)',
//         '--SideNav-color': 'var(--mui-palette-common-white)',
//         '--NavItem-color': 'var(--mui-palette-neutral-300)',
//         '--NavItem-hover-background': 'rgba(255, 255, 255, 0.04)',
//         '--NavItem-active-background': 'var(--mui-palette-primary-main)',
//         '--NavItem-active-color': 'var(--mui-palette-primary-contrastText)',
//         '--NavItem-disabled-color': 'var(--mui-palette-neutral-500)',
//         '--NavItem-icon-color': 'var(--mui-palette-neutral-400)',
//         '--NavItem-icon-active-color': 'var(--mui-palette-primary-contrastText)',
//         '--NavItem-icon-disabled-color': 'var(--mui-palette-neutral-600)',
//         bgcolor: 'var(--SideNav-background)',
//         color: 'var(--SideNav-color)',
//         display: { xs: 'none', lg: 'flex' },
//         flexDirection: 'column',
//         height: '100%',
//         left: 0,
//         maxWidth: '100%',
//         position: 'fixed',
//         top: 0,
//         width: 'var(--SideNav-width)',
//         zIndex: 'var(--SideNav-zIndex)',
//         overflowY: 'auto',
//         '&::-webkit-scrollbar': { width: '8px' },
//         '&::-webkit-scrollbar-thumb': { background: 'var(--mui-palette-neutral-700)', borderRadius: '4px' },
//       }}
//     >
//       <Stack spacing={2} sx={{ p: 3 }}>
//         <Box component={RouterLink} to={paths.home} sx={{ display: 'inline-flex' }}>
//           <Logo color="light" height={32} width={122} />
//         </Box>
//         <Box
//           sx={{
//             alignItems: 'center',
//             backgroundColor: 'var(--mui-palette-neutral-950)',
//             border: '1px solid var(--mui-palette-neutral-700)',
//             borderRadius: '12px',
//             cursor: 'pointer',
//             display: 'flex',
//             p: '4px 12px',
//           }}
//         >
//           <Box sx={{ flex: '1 1 auto' }}>
//             <Typography color="var(--mui-palette-neutral-400)" variant="body2">
//               Organization
//             </Typography>
//             <Typography color="inherit" variant="subtitle1">
//               {User.organization}
//             </Typography>
//           </Box>
//           <UnfoldMoreIcon />
//         </Box>
//       </Stack>
//       <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
//       <Box component="nav" sx={{ flex: '1 1 auto', p: '12px' }}>
//         {renderNavItems({ pathname: location.pathname, items: navItems })}
//       </Box>
//       <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
//       <Stack spacing={2} sx={{ p: '12px' }}>
//         {/* Additional items can go here */}
//       </Stack>
//     </Box>
//   );
// }

// const renderNavItems: React.FC<{ items?: NavItemConfig[]; pathname: string }> = ({ items = [], pathname }) => {
//   const children = items.reduce((acc: React.ReactNode[], curr: NavItemConfig): React.ReactNode[] => {
//     const { key, ...item } = curr;
//     acc.push(<NavItem key={key} pathname={pathname} {...item} />);
//     return acc;
//   }, []);

//   return (
//     <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
//       {children}
//     </Stack>
//   );
// }

// interface NavItemProps extends Omit<NavItemConfig, 'items'> {
//   pathname: string;
// }

// const NavItem: React.FC<NavItemProps> = ({ disabled, external, href, icon, matcher, pathname, title }) => {
//   const active = isNavItemActive({ disabled, external, href, matcher, pathname });
//   const Icon = icon ? navIcons[icon] : null;

//   return (
//     <li>
//       <Box
//         component={external ? 'a' : RouterLink}
//         to={href || '#'}
//         target={external ? '_blank' : undefined}
//         rel={external ? 'noreferrer' : undefined}
//         sx={{
//           alignItems: 'center',
//           borderRadius: 1,
//           color: 'var(--NavItem-color)',
//           cursor: 'pointer',
//           display: 'flex',
//           flex: '0 0 auto',
//           gap: 1,
//           p: '6px 16px',
//           position: 'relative',
//           textDecoration: 'none',
//           whiteSpace: 'nowrap',
//           ...(disabled && {
//             bgcolor: 'var(--NavItem-disabled-background)',
//             color: 'var(--NavItem-disabled-color)',
//             cursor: 'not-allowed',
//           }),
//           ...(active && { bgcolor: 'var(--NavItem-active-background)', color: 'var(--NavItem-active-color)' }),
//         }}
//       >
//         {Icon && (
//           <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flex: '0 0 auto' }}>
//             <Icon
//               fill={active ? 'var(--NavItem-icon-active-color)' : 'var(--NavItem-icon-color)'}
//             />
//           </Box>
//         )}
//         <Box sx={{ flex: '1 1 auto' }}>
//           <Typography
//             component="span"
//             sx={{ color: 'inherit', fontSize: '0.875rem', fontWeight: 500, lineHeight: '28px' }}
//           >
//             {title}
//           </Typography>
//         </Box>
//       </Box>
//     </li>
//   );
// }

// export default SideNav;

// 'use client';

// import * as React from 'react';
// import { Link as RouterLink, useLocation } from 'react-router-dom';
// import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

// import { isNavItemActive } from '../../../utils/IsNavItemActive';
// import { navItems } from './config';
// import { navIcons } from './NavIcons';
// import { paths } from '../../../paths';
// import { Logo } from '../../Core/Logo';
// import { Helper } from '../../../utils/Helper';
// import { UserPayload } from '../../../interfaces/UserPayload';
// import { NavItemConfig } from '../../../interfaces/NavItemConfig';
// import { Collapse } from '@mui/material';

// const SideNav: React.FC = () => {
//   const location = useLocation();

//   const [User, setUser] = React.useState<UserPayload>({ email: '', role: 1, organization: '' });
//   React.useEffect(() => {
//     const User = Helper.getCurrentUser();
//     setUser(User);
//   }, []);

//   return (
//     <Box
//       sx={{
//         '--SideNav-background': 'var(--mui-palette-neutral-950)',
//         '--SideNav-color': 'var(--mui-palette-common-white)',
//         '--NavItem-color': 'var(--mui-palette-neutral-300)',
//         '--NavItem-hover-background': 'rgba(255, 255, 255, 0.04)',
//         '--NavItem-active-background': 'var(--mui-palette-primary-main)',
//         '--NavItem-active-color': 'var(--mui-palette-primary-contrastText)',
//         '--NavItem-disabled-color': 'var(--mui-palette-neutral-500)',
//         '--NavItem-icon-color': 'var(--mui-palette-neutral-400)',
//         '--NavItem-icon-active-color': 'var(--mui-palette-primary-contrastText)',
//         '--NavItem-icon-disabled-color': 'var(--mui-palette-neutral-600)',
//         bgcolor: 'var(--SideNav-background)',
//         color: 'var(--SideNav-color)',
//         display: { xs: 'none', lg: 'flex' },
//         flexDirection: 'column',
//         height: '100%',
//         left: 0,
//         maxWidth: '100%',
//         position: 'fixed',
//         top: 0,
//         width: 'var(--SideNav-width)',
//         zIndex: 'var(--SideNav-zIndex)',
//         overflowY: 'auto',
//         '&::-webkit-scrollbar': { width: '8px' },
//         '&::-webkit-scrollbar-thumb': { background: 'var(--mui-palette-neutral-700)', borderRadius: '4px' },
//       }}
//     >
//       <Stack spacing={2} sx={{ p: 3 }}>
//         <Box component={RouterLink} to={paths.home} sx={{ display: 'inline-flex' }}>
//           <Logo color="light" height={32} width={122} />
//         </Box>
//         <Box
//           sx={{
//             alignItems: 'center',
//             backgroundColor: 'var(--mui-palette-neutral-950)',
//             border: '1px solid var(--mui-palette-neutral-700)',
//             borderRadius: '12px',
//             cursor: 'pointer',
//             display: 'flex',
//             p: '4px 12px',
//           }}
//         >
//           <Box sx={{ flex: '1 1 auto' }}>
//             <Typography color="var(--mui-palette-neutral-400)" variant="body2">
//               Organization
//             </Typography>
//             <Typography color="inherit" variant="subtitle1">
//               {User.organization}
//             </Typography>
//           </Box>
//           <UnfoldMoreIcon />
//         </Box>
//       </Stack>
//       <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
//       <Box component="nav" sx={{ flex: '1 1 auto', p: '12px' }}>
//         {renderNavItems({ pathname: location.pathname, items: navItems })}
//       </Box>
//       <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
//       <Stack spacing={2} sx={{ p: '12px' }}>
//         {/* Additional items can go here */}
//       </Stack>
//     </Box>
//   );
// };

// const renderNavItems: React.FC<{ items?: NavItemConfig[]; pathname: string }> = ({ items = [], pathname }) => {
//   return (
//     <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
//       {items.map((item) => (
//         <NavItem key={item.key} item={item} pathname={pathname} />
//       ))}
//     </Stack>
//   );
// };

// interface NavItemProps {
//   item: NavItemConfig;
//   pathname: string;
// }

// const NavItem: React.FC<NavItemProps> = ({ item, pathname }) => {
//   const [open, setOpen] = React.useState(false);
//   const active = isNavItemActive({ href: item.href, matcher: item.matcher, pathname });
//   const Icon = item.icon ? navIcons[item.icon] : null;

//   const handleToggle = () => {
//     setOpen((prevOpen) => !prevOpen);
//   };

//   return (
//     <li>
//       <Box
//         component={item.external ? 'a' : RouterLink}
//         to={item.href || '#'}
//         target={item.external ? '_blank' : undefined}
//         rel={item.external ? 'noreferrer' : undefined}
//         sx={{
//           alignItems: 'center',
//           borderRadius: 1,
//           color: 'var(--NavItem-color)',
//           cursor: 'pointer',
//           display: 'flex',
//           flex: '0 0 auto',
//           gap: 1,
//           p: '6px 16px',
//           position: 'relative',
//           textDecoration: 'none',
//           whiteSpace: 'nowrap',
//           ...(item.disabled && {
//             bgcolor: 'var(--NavItem-disabled-background)',
//             color: 'var(--NavItem-disabled-color)',
//             cursor: 'not-allowed',
//           }),
//           ...(active && { bgcolor: 'var(--NavItem-active-background)', color: 'var(--NavItem-active-color)' }),
//         }}
//         onClick={handleToggle}
//       >
//         {Icon && (
//           <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flex: '0 0 auto' }}>
//             <Icon
//               fill={active ? 'var(--NavItem-icon-active-color)' : 'var(--NavItem-icon-color)'}
//             />
//           </Box>
//         )}
//         <Box sx={{ flex: '1 1 auto' }}>
//           <Typography
//             component="span"
//             sx={{ color: 'inherit', fontSize: '0.875rem', fontWeight: 500, lineHeight: '28px' }}
//           >
//             {item.title}
//           </Typography>
//         </Box>
//       </Box>
//       {item.items && (
//         <Collapse in={open}>
//           {renderNavItems({ items: item.items, pathname })}
//         </Collapse>
//       )}
//     </li>
//   );
// };

// export default SideNav;

'use client';

import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { isNavItemActive } from '../../../utils/IsNavItemActive';
import { navItems } from './config';
import { navIcons } from './NavIcons';
import { paths } from '../../../paths';
import { Logo } from '../../Core/Logo';
import { Helper } from '../../../utils/Helper';
import { UserPayload } from '../../../interfaces/UserPayload';
import { NavItemConfig } from '../../../interfaces/NavItemConfig';

const SideNav: React.FC = () => {
  const location = useLocation();

  const [User, setUser] = React.useState<UserPayload>({ email: '', role: 1, organization: 'Virtacc' });
  React.useEffect(() => {
    const User = Helper.getCurrentUser();
    setUser(User);
  }, []);

  return (
    <Box
      sx={{
        '--SideNav-background': 'var(--mui-palette-neutral-950)',
        '--SideNav-color': 'var(--mui-palette-common-white)',
        '--NavItem-color': 'var(--mui-palette-neutral-300)',
        '--NavItem-hover-background': 'rgba(255, 255, 255, 0.04)',
        '--NavItem-active-background': 'var(--mui-palette-primary-main)',
        '--NavItem-active-color': 'var(--mui-palette-primary-contrastText)',
        '--NavItem-disabled-color': 'var(--mui-palette-neutral-500)',
        '--NavItem-icon-color': 'var(--mui-palette-neutral-400)',
        '--NavItem-icon-active-color': 'var(--mui-palette-primary-contrastText)',
        '--NavItem-icon-disabled-color': 'var(--mui-palette-neutral-600)',
        bgcolor: 'var(--SideNav-background)',
        color: 'var(--SideNav-color)',
        display: { xs: 'none', lg: 'flex' },
        flexDirection: 'column',
        height: '100%',
        left: 0,
        maxWidth: '100%',
        position: 'fixed',
        top: 0,
        width: 'var(--SideNav-width)',
        zIndex: 'var(--SideNav-zIndex)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': { width: '8px' },
        '&::-webkit-scrollbar-thumb': { background: 'var(--mui-palette-neutral-700)', borderRadius: '4px' },
      }}
    >
      <Stack spacing={2} sx={{ p: 3 }}>
        <Box component={RouterLink} to={paths.home} sx={{ display: 'inline-flex' }}>
          <Logo color="light" height={32} width={122} />
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'var(--mui-palette-neutral-950)',
            border: '1px solid var(--mui-palette-neutral-700)',
            borderRadius: '12px',
            cursor: 'pointer',
            display: 'flex',
            p: '4px 12px',
          }}
        >
          <Box sx={{ flex: '1 1 auto' }}>
            <Typography color="var(--mui-palette-neutral-400)" variant="body2">
              Organization
            </Typography>
            <Typography color="inherit" variant="subtitle1">
              {User.organization}
            </Typography>
          </Box>
          {/* <ExpandMoreIcon /> */}
        </Box>
      </Stack>
      <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
      <Box component="nav" sx={{ flex: '1 1 auto', p: '12px' }}>
        {renderNavItems({ pathname: location.pathname, items: navItems })}
      </Box>
      <Divider sx={{ borderColor: 'var(--mui-palette-neutral-700)' }} />
      <Stack spacing={2} sx={{ p: '12px' }}>
        {/* Additional items can go here */}
      </Stack>
    </Box>
  );
};

const renderNavItems: React.FC<{ items?: NavItemConfig[]; pathname: string }> = ({ items = [], pathname }) => {
  return (
    <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
      {items.map((item) => (
        <NavItem key={item.key} item={item} pathname={pathname} />
      ))}
    </Stack>
  );
};

interface NavItemProps {
  item: NavItemConfig;
  pathname: string;
}

const NavItem: React.FC<NavItemProps> = ({ item, pathname }) => {
  const [open, setOpen] = React.useState(false);
  const active = isNavItemActive({ href: item.href, matcher: item.matcher, pathname });
  const Icon = item.icon ? navIcons[item.icon] : null;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <li>
      <Box
        component={item.external ? 'a' : RouterLink}
        to={item.href || '#'}
        target={item.external ? '_blank' : undefined}
        rel={item.external ? 'noreferrer' : undefined}
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          color: 'var(--NavItem-color)',
          cursor: 'pointer',
          display: 'flex',
          flex: '0 0 auto',
          gap: 1,
          p: '6px 16px',
          position: 'relative',
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          ...(item.disabled && {
            bgcolor: 'var(--NavItem-disabled-background)',
            color: 'var(--NavItem-disabled-color)',
            cursor: 'not-allowed',
          }),
          ...(active && { bgcolor: 'var(--NavItem-active-background)', color: 'var(--NavItem-active-color)' }),
        }}
        onClick={handleToggle}
      >
        {Icon && (
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flex: '0 0 auto' }}>
            <Icon
              fill={active ? 'var(--NavItem-icon-active-color)' : 'var(--NavItem-icon-color)'}
            />
          </Box>
        )}
        <Box sx={{ flex: '1 1 auto' }}>
          <Typography
            component="span"
            sx={{ color: 'inherit', fontSize: '0.875rem', fontWeight: 500, lineHeight: '28px' }}
          >
            {item.title}
          </Typography>
        </Box>
        {item.items && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </Box>
        )}
      </Box>
      {item.items && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ pl: 4 }}>
            {renderNavItems({ items: item.items, pathname })}
          </Box>
        </Collapse>
      )}
    </li>
  );
};

export default SideNav;
