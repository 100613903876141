import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Divider,
  InputAdornment,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ProductsType } from '../../../interfaces/Products';
import SyncIcon from '@mui/icons-material/Sync';
import dayjs from 'dayjs';
import ProductService from '../../../templates/Dashboard/Products/api/Products.api';
import SearchIcon from '@mui/icons-material/Search';

export interface ProductProps {
  sx?: SxProps;
  products?: ProductsType[];
}

const ProductsTable: React.FC<ProductProps> = ({ products = [], sx }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredProducts, setFilteredProducts] = React.useState<ProductsType[]>(products);
  const [editIndex, setEditIndex] = React.useState(null || 99999999999999);
  const [updatedCost, setUpdatedCost] = React.useState(0);

  React.useEffect(() => {
    setFilteredProducts(products);
  }, [products])

  // Handle Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filtering logic (you can adjust this based on your filtering requirements)
  const handleFilterChange = (filterValue: string) => {
    const filtered = products.filter(product =>
      product.SellerSKU.includes(filterValue) ||
      product.ASIN.includes(filterValue)
    );
    setFilteredProducts(filtered);
  };

  const paginatedProducts = filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Handle Cost Input Change
  const handleChangeCostInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedCost(parseFloat(event.target.value))
  };

  // Handle edit button click
  const handleEditClick = (index: number) => {
    setEditIndex(index);
    setUpdatedCost(products[index].Cost);
  };

  // Handle save button click
  const handleSaveClick = async (sku: string) => {
    await ProductService.UpdateProductCost(sku, updatedCost);
    window.location.reload();
    setEditIndex(99999999999999); // Exit edit mode
  };

  return (
    <>
      <Card sx={{ p: 2 }}>
        <OutlinedInput
          defaultValue=""
          fullWidth
          placeholder="Search by ASIN or SKU"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          sx={{ maxWidth: '500px' }}
          onChange={(e) => handleFilterChange(e.target.value)}
        />
      </Card>
      <Card sx={sx}>
        <CardHeader
          action={
            <>
              <Button color="inherit" size="small" startIcon={<SyncIcon />}>
                Sync
              </Button>
              <Button variant='contained' size="small">
                Download
              </Button>
            </>
          }
        />
        <Divider />
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Seller SKU</TableCell>
                <TableCell>FnSKU</TableCell>
                <TableCell>ASIN</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Marketplace</TableCell>
                <TableCell>Condition</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.map((Product, index) => {
                return (
                  <TableRow hover={true} key={Product.SellerSKU} >
                    <TableCell sx={{ minWidth: 200 }}>{Product.ProductName}</TableCell>
                    <TableCell>{Product.SellerSKU}</TableCell>
                    <TableCell>{Product.FnSKU}</TableCell>
                    <TableCell>{Product.ASIN}</TableCell>
                    <TableCell>{Product.TotalQuantity}</TableCell>
                    <TableCell>{Product.Marketplace}</TableCell>
                    <TableCell>{Product.Condition}</TableCell>
                    <TableCell>{dayjs(Product.LastUpdated).format('MMM D, YYYY')}</TableCell>
                    {/* <TableCell><input
                      type="number"
                      value={costs[Product.SellerSKU] || ''}
                      onChange={(e) => handleCostChange(Product.SellerSKU, e.target.value)}
                    /></TableCell>
                    <TableCell><button onClick={() => handleSaveCost(Product.SellerSKU)}>Save Cost</button></TableCell> */}
                    <TableCell key={Product.SellerSKU}>
                      {editIndex === index ? (
                        <OutlinedInput
                          defaultValue={Product.Cost}
                          sx={{ width: '100px' }}
                          onChange={handleChangeCostInput}
                        />
                      ) : (<span>{Product.Cost}</span>)}
                    </TableCell>
                    <TableCell key={Product.SellerSKU}>
                      {editIndex === index ? (<Stack display={'flex'} justifyContent={'space-between'}>
                        <Chip color={'success'} label={'Save'} onClick={() => handleSaveClick(Product.SellerSKU)} />
                        <Chip color={'default'} label={'Cancel'} onClick={() => setEditIndex(99999999999999)} />
                      </Stack>
                        // <button onClick={() => handleSaveClick(Product.SellerSKU)}>Save</button>

                      ) : (
                        <Chip color={'default'} label={'Edit'} onClick={() => handleEditClick(index)} />
                        // <button onClick={() => handleEditClick(index)}>Edit</button>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            color="inherit"
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="text"
          >
            View all
          </Button>
        </CardActions>
        <TablePagination
          component="div"
          count={filteredProducts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
}

export default ProductsTable;