import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from 'dayjs';

// const statusMap = {
//   pending: { label: 'Pending', color: 'warning' },
//   delivered: { label: 'Delivered', color: 'success' },
//   refunded: { label: 'Refunded', color: 'error' },
// } as const;

export interface Order {
  id: string;
  customer: { name: string };
  amount: number;
  status: 'pending' | 'delivered' | 'refunded';
  createdAt: Date;
}

export interface Organization {
  id: number;
  name: string;
  owner: string;
  phone: string;
  email: string;
  createdAt: Date;
}

export interface OrganizationProps {
  sx?: SxProps;
  organizations?: Organization[];
}

function changeStatus() {
  // console.log('clicked');
}

const AllOrganizations: React.FC<OrganizationProps> = ({ organizations = [], sx }) => {
  return (
    <Card sx={sx}>
      <CardHeader title="Organizations" />
      <Divider />
      <Box sx={{ overflowX: 'auto' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Phone</TableCell>
              {/* <TableCell sortDirection="desc">Date</TableCell> */}
              <TableCell>Email</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((organization) => {
            //   const { label, color } = statusMap[order.status] ?? { label: 'Unknown', color: 'default' };

              return (
                <TableRow hover key={organization.id}>
                  <TableCell>{organization.id}</TableCell>
                  <TableCell>{organization.name}</TableCell>
                  <TableCell>{organization.owner}</TableCell>
                  <TableCell>{organization.phone}</TableCell>
                  <TableCell>{organization.email}
                    {/* <Chip color={color} label={label} size="small" /> */}
                  </TableCell>
                  <TableCell>{dayjs(organization.createdAt).format('MMM D, YYYY')}</TableCell>
                  <TableCell><Chip component={Button} onClick={changeStatus} color='success' label='Active' /></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={<ArrowForwardIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
      {/* <TablePagination 
        component="div"
        count={count}
        onPageChange={noop}
        onRowsPerPageChange={noop}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
}

export default AllOrganizations;