// Example: OrderFilter.tsx
import React, { useState } from 'react';
import { OrderFilters } from '../../interfaces/test';

interface OrderFilterProps {
    onFilter: (filters: OrderFilters) => void;
}

const OrderFilter: React.FC<OrderFilterProps> = ({ onFilter }) => {
    const [status, setStatus] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onFilter({ status, startDate, endDate });
    };

    return (
        <form onSubmit={handleSubmit}>
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="">All</option>
                <option value="Pending">Pending</option>
                <option value="Shipped">Shipped</option>
                <option value="Canceled">Canceled</option>
            </select>

            <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
            />

            <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
            />

            <button type="submit">Filter</button>
        </form>
    );
};

export default OrderFilter;
