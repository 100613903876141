import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

type DataItem = {
  name: string;
  value: number;
  details?: string[];  // Bifurcation details
};

const data: DataItem[] = [
  { name: 'Profit', value: 400, details: ['Product Sales', 'Investments'] },
  { name: 'Expense', value: 300, details: ['Salaries', 'Rent', 'Utilities'] },
];

const COLORS = ['#0088FE', '#FF8042'];

const CustomPieChart: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<DataItem | null>(null);

  const handleClick = (data: DataItem) => {
    setSelectedData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedData(null);
  };

  return (
    <Box sx={{ width: '100%', height: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
            onClick={(entry) => handleClick(entry)}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>

      {/* Dialog for Bifurcation Details */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedData?.name} Bifurcation</DialogTitle>
        <DialogContent>
          {selectedData?.details?.map((detail, index) => (
            <Typography key={index}>{detail}</Typography>
          ))}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CustomPieChart;
