import * as React from 'react';
import { Avatar, Card, CardContent, LinearProgress, Stack, Typography } from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import ListIcon from '@mui/icons-material/List';

export interface TasksProgressProps {
  sx?: SxProps;
  value: number;
}

const TasksProgress: React.FC<TasksProgressProps> = ({ value, sx }) => {
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
            <Stack spacing={1}>
              <Typography color="text.secondary" gutterBottom variant="overline">
                Task Progress
              </Typography>
              <Typography variant="h4">{value}%</Typography>
            </Stack>
            <Avatar sx={{ backgroundColor: 'var(--mui-palette-warning-main)', height: '56px', width: '56px' }}>
              <ListIcon />
            </Avatar>
          </Stack>
          <div>
            <LinearProgress value={value} variant="determinate" />
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default TasksProgress;